
@import "libs";
@import "vars";
@import "reset";


* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }



html, body {
	height: 100%;
	background: #F2F6E3; }
body {
	font-size: 23px;
	min-width: 320px;
	position: relative;
	line-height: 1.2;
	font-family: $fd;
	overflow-x: hidden;
	color: #000;
	background: #F2F6E3; }
img {
	max-width: 100%; }
.hidden {
	display: none; }

ul,
li {
	list-style-type: none;
	padding: 0;
	margin: 0; }
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box; }
.clearfix::after {
	content: "";
	display: table;
	clear: both; }
a {
	transition: all 0.3s;
	text-decoration: none;
	color: #000;
	&:hover {
		opacity: .8; } }
img,
input,
textarea {
	max-width: 100%; }
h1, .h1 {
	font-size: 50px;
	display: none; }
h2, .h2 {
	font-size: 60px;
	font-family: $cb;
	text-align: center;
	text-transform: uppercase; }
h3, .h3 {
	font-size: 30px; }
h4, .h4 {
	font-size: 23px; }

h5, .h5 {
	font-size: 30px; }
h6, .h6 {
	font-size: 16px; }
.h1-block {
	.descr {
		font-size: 20px; }
	img {
		position: relative;
		bottom: -25px; } }

.h2-block {
	text-align: center;
	margin-bottom: 70px;
	.descr {
		font-size: 20px;
		text-transform: uppercase; } }


.s-section {
	padding: 120px 0 55px;
	background: #F2F6E3; }
.wrap-items {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap; }

.button, a.button, button {
	border: none;
	&:hover {
		opacity: .8; } }
.header {
	padding: 1% 0;
	min-height: 115px;
	width: 100%;
	left: 0;
	top: 0;
	position: fixed;
	background: #F2F6E3;
	z-index: 999;
	.logo {
		margin-right: 50px; } }
.top-header-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	nav {
		ul {
			display: flex;
			justify-content: space-between; } }

	a {
		text-transform: uppercase; }
	.navigation {
		flex-grow: 1; }
	.ssoc-block {
		margin: 0 50px;
		opacity: 0; } }
.lang-block {
	a {
		position: relative;
		width: 35px;
		height: 35px;
		overflow: hidden;
		line-height: 35px;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background: #F2F6E3; } }
	li {
		display: inline-block; }
	.uk {
		&:before {
			content: "ua"; } }
	.en {
		display: none;
		&:before {
			content: "en"; } } }

.ssoc-wrap {
	font-size: 40px; }
main {
	background: #F2F6E3;
	overflow: hidden; }
.s-header {
	background:  url("../img/header-bg.png") 50% 50% no-repeat;
	background-size: contain;
	margin-top: 115px; }
.h1-block {
	text-align: center;
	height: calc(90vh - 115px);
	min-height: 785px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	max-width: 84%;
	margin: auto; }
.s-brends {
	padding: 60px 0;
	background: #F2F6E3;
	.slick-track {
		display: flex;
		align-items: center; }
	.item {
		a {
			margin: auto;
			display: block;
			padding: 0 25px; }

		img {
			margin: auto;
			max-width: 160px; } } }

.s-about {
	background: url("../img/big-bg.png") no-repeat;
	background-size: cover;
	height: 100vh;
	min-height: 1000px;
	position: relative;
	.h2-block {
		padding-top: 80px; }
	.text-block {
		padding: 80px 25px 65px;
		background: #F4F4F4;
		border-radius: 20px;
		text-align: center;
		text-align: justify;
		line-height: 1.8;
		margin: auto;
		max-width: 80%;
		position: relative;
		p {
			text-indent: 2em;
			padding: 5px 0; } }
	h2 {
		color: #fff; }
	.im-01 {
		position: absolute;
		width: 14%;
		left: 2%;
		top: -45px; }
	.im-02 {
		position: absolute;
		width: 25%;
		right: 1%;
		top: -135px; }
	.im-03 {
		position: absolute;
		width: 30%;
		right: -15%;
		bottom: -150px; }
	.im-04 {
		position: absolute;
		width: 20%;
		right: 40%;
		bottom: -70px; }
	.im-05 {
		position: absolute;
		width: 37%;
		left: 0;
		bottom: -70px; } }

.s-advantages {
	text-align: center;
	h2 {
		margin-bottom: 50px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: 20%;
			height: 2px;
			background: #000;
			left: 40%;
			bottom: -15px; } }
	.item {
		margin: 15px 0;
		width: 32%; }
	h4 {
		font-size: 23px;
		text-transform: uppercase; }
	.img-block {
		height: 132px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		img {
			display: block !important; } }
	.title {
		margin: 25px 0; } }

.s-assortment {
	.item {
		margin: 15px 15px;
		width: calc(100% / 3 - 30px);
		text-align: center; }
	h3 {
		font-size: 40px;
		text-transform: uppercase;
		font-family: $cb;
		text-align: center; }
	.img-block {
		height: 330px;
		display: flex;
		align-items: center;
		justify-content: center; } }
.s-products .show-block.active {
	.img-big {
		display: none; }
	.text-block {
		text-align: left; } }
.gal-big-block {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(#000, 1);
	z-index: 999;
	display: none;
	&.active {
		display: block; }
	.wrap-gal {
		max-width: 800px;
		max-height: 90vh;
		margin: auto;
		background: #000;
		padding: 40px; }
	.close-l {
		color: #fff;
		font-size: 40px;
		cursor: pointer;
		width: 40px;
		height: 40px;
		position: absolute;
		right: 15px;
		top: 15px; } }


.s-contacts {
	background: url("../img/footer-bhome-bg.png") no-repeat;
	background-size: cover !important;
	color: #fff;
	position: relative;
	background-repeat: no-repeat;
	h2 {
		font-family: $ceb; }
	a {
		color: #fff;
		text-decoration: underline; }
	.wrap-items {
		align-items: center;
		min-height: 700px;
		height: 100vh; }
	.item-info {
		margin: 30px 0; }
	.ssoc-block {
		font-size: 40px;
		display: none; }
	.button-form {
		background: #39B54A;
		color: #fff;
		font-size: 23px;
		padding: 10px 80px;
		border-radius: 10px; }
	input, textarea {
		border: none;
		border-bottom: 2px solid #7C7D7C;
		background: 0 0;
		color: #fff; }
	textarea {
		width: 100%;
		height: 170px; }
	label {
		margin: 30px 0; }

	::placeholder {
		color: #fff; }
	.button-block {
		margin: 30px 0; }
	button {
		border: 2px solid #7C7D7C; }
	.item-form {
		width: 50%;
		padding: 0 50px; }
	.cont-im-01 {
		display: none; }
	.item-cont {
		margin-right: 10%; } }
.ishome {
	.cont-im-01 {
		position: absolute;
		left: 0;
		top: 40%;
		width: 9%;
		display: block; } }


////////////////////////////////////////////////////////////////////////////
.wrap-img-mob {
	display: none;
	img {
		display: block; } }
.header-block {
	height: 785px;
	position: relative;
	//height: calc(100vh - 115px)
	margin-top: 115px; }
.info-block {
	width: 40%;
	position: absolute;
	top: 0;
	right: 10%;
	height: 100%;
	display: flex;
	align-items: center;
	.wrap-info {
		background: #fff;
		padding: 35px 40px;
		text-align: center;
		width: 100%;
		p {
			text-indent: 2em;
			padding: 5px 0; } }
	.item {
		margin: 15px 0 60px; }

	.logo-brend {
		margin-bottom: 30px;
		img {
			max-height: 200px; } }
	.text-block {
		text-align: justify; }
	.wrap-items {
		width: 100%;
		justify-content: space-around; } }
.s-prods {
	text-align: center;
	background: #fff;
	padding: 150px 0;
	.wrap-items {
		justify-content: space-around; }
	.title {
		margin-top: 25px;
		text-transform: uppercase;
		font-size: 28px; }
	.img-block {
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: flex-end; }
	.item, label {
		max-width: 330px; }
	input {
		display: none; }
	sup {
		display: none !important; } }

.s-products {
	background: url("../img/grey-bg.jpg") no-repeat;
	background-size: cover;
	padding: 130px 0 150px;
	position: relative;
	form {
		height: 100%; }
	.wrap-items {
		justify-content: flex-start; }
	.item {
		width: calc(100% /  4 - 30px);
		border-radius: 30px;
		background: #fff;
		border: 3px dotted #000;
		padding: 30px 30px;
		text-align: center;
		transition: all 1.3s;
		position: relative;
		margin: 30px 15px;
		.a {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%; }

		&:nth-child(4n + 4) {
			.show-block {
				left: {}
				right: -15px; } } }
	h3 {
		text-transform: uppercase;
		font-size: 26px; }
	.content-block {
		position: relative;
		transition: all .3s;
		top: 0;
		flex-grow: 1; }
	.button-block {
		margin-top: 10px;

		transition: all .5s;
		width: 100%;
		height: 46px;
		span {
			display: inline-block;
			font-size: 25px;
			border-radius: 19.5px;
			border: 1px solid #000;
			width: 80%;
			padding: 10px 25px;
			text-transform: uppercase;
			cursor: pointer;
			transition: all .3s; }
		&:hover {
			span {
				background: #333;
				color: #fff; } } }
	.img-block {
		height: 300px;
		margin-bottom: 15px;
		cursor: pointer;
		img {
			max-height: 100%; } }
	.show-block {
		position: absolute;
		background: #fff;
		width: 500px;
		top: -15px;
		left: -15px;
		z-index: 9;
		border: 3px solid #ccc;
		border-radius: 20px;
		padding: 25px 25px;
		transition: all .5s;
		opacity: 0;
		display: none;
		&.active {
			display: block;
			opacity: 1; } }
	.close-prod {
		position: absolute;
		font-size: 50px;
		top: 10px;
		right: 10px;
		cursor: pointer; }
	.img-big {
		border: 2px dashed #ccc;
		border-radius: 20px;
		padding: 15px;
		margin: 0 20px; }
	.it {
		display: flex;
		justify-content: space-between;
		margin: 15px 0; }
	.name {
		margin: 30px 0; }
	.descr {
		margin: 30px 0; }
	.ttl {
		min-width: 140px;
		text-align: left; }
	.des {
		text-align: right; } }
.s-products .mre {
	cursor: pointer;
	transition: all .3s;
	&:hover {
		opacity: .8; } }
#mse2_mfilter .pagination {
	display: flex;
	justify-content: center;
	.page-item {
		margin: 0 10px;
		&.active {
			a {
				font-weight: bold; } } } }
.s-product.s-section {
	padding-top: 200px;
	.section-wrap {
		min-height: 70vh;
		display: flex; }
	.it {
		display: flex;
		justify-content: space-between;
		margin: 15px 0; }
	.name {
		margin: 30px 0; }
	.descr {
		margin: 30px 0; } }

.baltic-fish-01 {
	width: 23%;
	height: 100%;
	background: url("../img/meat.png") no-repeat;
	background-size: contain;
	position: absolute;
	top: 600px;
	right: -200px;
	display: none; }
#mse2_filters .disabled, #mse2_filters .disabled a {
	color: #aaa; }
.ass-block {
	color: #fff;
	text-align: center;
	.wrap-info {
		background: 0 0; }
	.wrap-info {
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		text-transform: uppercase;
		justify-content: space-between;
		label {
			width: 30%; } }
	label {
		cursor: pointer; }
	.item {
		width: 100%; }
	.item-one {
		transition: all .3s;
		p {
			text-indent: 0;
			padding: 0; }
		img {
			max-height: 70px; }
		&:hover {
			opacity: 1; } }
	input {
		display: none; }
	.wrap-img {
		height: 90px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: 15px; } }

.s-all-br {
	background: #fff;
	padding: 150px 0;
	.wrap-items {
		justify-content: center; }
	.item {
		border: 1px dotted #000;
		width: calc(100% / 6 - 30px);
		height: 190px;
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 15px 15px;
		padding: 15px;
		//display: none
		img {
			max-height: 160px; } }

	input {
		display: none; } }
.ass-block .wrap-info {
	font-size: 18px;
	button {
		width: 100%;
		text-transform: uppercase;
		border: none;
		background: 0 0;
		text-align: center;
		font-size: 23px;
		color: #fff;
		&.hidden {
			display: block !important; } } }
.item-one {
	opacity: .6; }
form {
	sup {
		display: none; } }

footer {
	text-align: center;
	padding: 30px 0; }
#thanks {
	text-align: center;
	.form-title {
		margin-bottom: 15px; }
	.mfp-close {
		color: #fff !important; } }

.error {
	display: none;
	color: red; }


//.mfp-bg
//	background: #eee
//.mfp-close-btn-in .mfp-close
//	color: #fff

.form-popup {
	max-width: 330px;
	background: rgba(28, 28, 28, 0.76);
	color: #fff;
	padding: 40px 25px;
	border-radius: 20px;
	position: relative;
	margin: auto; }

.form-temp {
	.form-title {
		font-size: 30px;
		text-align: center;
		margin-bottom: 25px; }
	input {
		width: 100%;
		height: 40px; }
	label {
		margin-bottom: 10px;
		display: block; } }

.check-block {
	font-size: 14px;
	display: flex;
	align-items: center;
	margin: 15px 0;
	label {
		display: flex;
		align-items: center;
		margin: 0; }
	input {
		margin-right: 10px;
		min-width: 16px;
		width: 16px;
		height: 16px; } }

.fotorama__stage {
	max-height: 90vh !important;
	top: 0 !important;
	width: auto !important;
	margin: 0 auto; }
.fotorama__wrap--css3 .fotorama__stage .fotorama__img {
	max-height: 70vh !important;
	top: 0 !important;
	width: auto !important;
	margin: 0 auto; }
.fotorama__nav-wrap {
	position: absolute;
	top: 76vh; }
.fotorama__thumb {
	img {
		min-height: 100%; } }







.close {
	display: none; }
.mobil-mnu {
	display: none; }
.mobil-mnu {
	color: #000; }
.toggle-mnu {
	display: block;
	width: 28px;
	height: 28px;


	span:after, span:before {
		content: "";
		position: absolute;
		left: 0;
		top: 9px; }

	span:after {
		top: 18px; }

	span {
		position: relative;
		display: block; }

	span, span:after, span:before {
		width: 100%;
		height: 2px;
		background-color: #000;
		transition: all 0.3s;
		backface-visibility: hidden;
		border-radius: 2px; }


	&.on span {
		background-color: transparent; }

	&.on span:before {
		transform: rotate(45deg) translate(-1px, 0px); }

	&.on span:after {
		transform: rotate(-45deg) translate(6px, -7px); } }

@import "media"; // Всегда в конце
