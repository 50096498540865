@import url(../libs/bootstrap-grid/bootstrap-grid.css);

@import url(../libs/magnific-popup/magnific-popup.css);

@import url(../libs/slick_work/slick.css);

@import url(../libs/slick_work/slick-theme.css);

@import url(../libs/fotorama/fotorama.css);

@import url(../fonts/icomoon/style.css);

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

input,
textarea,
select {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

button,
.button {
  cursor: pointer;
}

a {
  display: inline-block;
  text-decoration: none;
}

a:active,
a:focus {
  outline: none;
}

.slick-slide,
a,
button,
.button,
input,
textarea {
  outline: none;
}

strong {
  font-weight: bold;
}

form label {
  display: block;
}

form label span {
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

*:-moz-placeholder {
  color: #666;
  opacity: 1;
}

*::-moz-placeholder {
  color: #666;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

html,
body {
  height: 100%;
  background: #F2F6E3;
}

body {
  font-size: 23px;
  min-width: 320px;
  position: relative;
  line-height: 1.2;
  font-family: "CirceRegular", sans-serif;
  overflow-x: hidden;
  color: #000;
  background: #F2F6E3;
}

img {
  max-width: 100%;
}

.hidden {
  display: none;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

a {
  transition: all 0.3s;
  text-decoration: none;
  color: #000;
}

a:hover {
  opacity: .8;
}

img,
input,
textarea {
  max-width: 100%;
}

h1,
.h1 {
  font-size: 50px;
  display: none;
}

h2,
.h2 {
  font-size: 60px;
  font-family: "CirceBold", sans-serif;
  text-align: center;
  text-transform: uppercase;
}

h3,
.h3 {
  font-size: 30px;
}

h4,
.h4 {
  font-size: 23px;
}

h5,
.h5 {
  font-size: 30px;
}

h6,
.h6 {
  font-size: 16px;
}

.h1-block .descr {
  font-size: 20px;
}

.h1-block img {
  position: relative;
  bottom: -25px;
}

.h2-block {
  text-align: center;
  margin-bottom: 70px;
}

.h2-block .descr {
  font-size: 20px;
  text-transform: uppercase;
}

.s-section {
  padding: 120px 0 55px;
  background: #F2F6E3;
}

.wrap-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button,
a.button,
button {
  border: none;
}

.button:hover,
a.button:hover,
button:hover {
  opacity: .8;
}

.header {
  padding: 1% 0;
  min-height: 115px;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: #F2F6E3;
  z-index: 999;
}

.header .logo {
  margin-right: 50px;
}

.top-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header-wrap nav ul {
  display: flex;
  justify-content: space-between;
}

.top-header-wrap a {
  text-transform: uppercase;
}

.top-header-wrap .navigation {
  flex-grow: 1;
}

.top-header-wrap .ssoc-block {
  margin: 0 50px;
  opacity: 0;
}

.lang-block a {
  position: relative;
  width: 35px;
  height: 35px;
  overflow: hidden;
  line-height: 35px;
}

.lang-block a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #F2F6E3;
}

.lang-block li {
  display: inline-block;
}

.lang-block .uk:before {
  content: "ua";
}

.lang-block .en {
  display: none;
}

.lang-block .en:before {
  content: "en";
}

.ssoc-wrap {
  font-size: 40px;
}

main {
  background: #F2F6E3;
  overflow: hidden;
}

.s-header {
  background: url("../img/header-bg.png") 50% 50% no-repeat;
  background-size: contain;
  margin-top: 115px;
}

.h1-block {
  text-align: center;
  height: calc(90vh - 115px);
  min-height: 785px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-width: 84%;
  margin: auto;
}

.s-brends {
  padding: 60px 0;
  background: #F2F6E3;
}

.s-brends .slick-track {
  display: flex;
  align-items: center;
}

.s-brends .item a {
  margin: auto;
  display: block;
  padding: 0 25px;
}

.s-brends .item img {
  margin: auto;
  max-width: 160px;
}

.s-about {
  background: url("../img/big-bg.png") no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 1000px;
  position: relative;
}

.s-about .h2-block {
  padding-top: 80px;
}

.s-about .text-block {
  padding: 80px 25px 65px;
  background: #F4F4F4;
  border-radius: 20px;
  text-align: center;
  text-align: justify;
  line-height: 1.8;
  margin: auto;
  max-width: 80%;
  position: relative;
}

.s-about .text-block p {
  text-indent: 2em;
  padding: 5px 0;
}

.s-about h2 {
  color: #fff;
}

.s-about .im-01 {
  position: absolute;
  width: 14%;
  left: 2%;
  top: -45px;
}

.s-about .im-02 {
  position: absolute;
  width: 25%;
  right: 1%;
  top: -135px;
}

.s-about .im-03 {
  position: absolute;
  width: 30%;
  right: -15%;
  bottom: -150px;
}

.s-about .im-04 {
  position: absolute;
  width: 20%;
  right: 40%;
  bottom: -70px;
}

.s-about .im-05 {
  position: absolute;
  width: 37%;
  left: 0;
  bottom: -70px;
}

.s-advantages {
  text-align: center;
}

.s-advantages h2 {
  margin-bottom: 50px;
  position: relative;
}

.s-advantages h2:before {
  content: "";
  position: absolute;
  width: 20%;
  height: 2px;
  background: #000;
  left: 40%;
  bottom: -15px;
}

.s-advantages .item {
  margin: 15px 0;
  width: 32%;
}

.s-advantages h4 {
  font-size: 23px;
  text-transform: uppercase;
}

.s-advantages .img-block {
  height: 132px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.s-advantages .img-block img {
  display: block !important;
}

.s-advantages .title {
  margin: 25px 0;
}

.s-assortment .item {
  margin: 15px 15px;
  width: calc(100% / 3 - 30px);
  text-align: center;
}

.s-assortment h3 {
  font-size: 40px;
  text-transform: uppercase;
  font-family: "CirceBold", sans-serif;
  text-align: center;
}

.s-assortment .img-block {
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-products .show-block.active .img-big {
  display: none;
}

.s-products .show-block.active .text-block {
  text-align: left;
}

.gal-big-block {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: black;
  z-index: 999;
  display: none;
}

.gal-big-block.active {
  display: block;
}

.gal-big-block .wrap-gal {
  max-width: 800px;
  max-height: 90vh;
  margin: auto;
  background: #000;
  padding: 40px;
}

.gal-big-block .close-l {
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.s-contacts {
  background: url("../img/footer-bhome-bg.png") no-repeat;
  background-size: cover !important;
  color: #fff;
  position: relative;
  background-repeat: no-repeat;
}

.s-contacts h2 {
  font-family: "CirceExtraBold", sans-serif;
}

.s-contacts a {
  color: #fff;
  text-decoration: underline;
}

.s-contacts .wrap-items {
  align-items: center;
  min-height: 700px;
  height: 100vh;
}

.s-contacts .item-info {
  margin: 30px 0;
}

.s-contacts .ssoc-block {
  font-size: 40px;
  display: none;
}

.s-contacts .button-form {
  background: #39B54A;
  color: #fff;
  font-size: 23px;
  padding: 10px 80px;
  border-radius: 10px;
}

.s-contacts input,
.s-contacts textarea {
  border: none;
  border-bottom: 2px solid #7C7D7C;
  background: 0 0;
  color: #fff;
}

.s-contacts textarea {
  width: 100%;
  height: 170px;
}

.s-contacts label {
  margin: 30px 0;
}

.s-contacts ::placeholder {
  color: #fff;
}

.s-contacts .button-block {
  margin: 30px 0;
}

.s-contacts button {
  border: 2px solid #7C7D7C;
}

.s-contacts .item-form {
  width: 50%;
  padding: 0 50px;
}

.s-contacts .cont-im-01 {
  display: none;
}

.s-contacts .item-cont {
  margin-right: 10%;
}

.ishome .cont-im-01 {
  position: absolute;
  left: 0;
  top: 40%;
  width: 9%;
  display: block;
}

.wrap-img-mob {
  display: none;
}

.wrap-img-mob img {
  display: block;
}

.header-block {
  height: 785px;
  position: relative;
  margin-top: 115px;
}

.info-block {
  width: 40%;
  position: absolute;
  top: 0;
  right: 10%;
  height: 100%;
  display: flex;
  align-items: center;
}

.info-block .wrap-info {
  background: #fff;
  padding: 35px 40px;
  text-align: center;
  width: 100%;
}

.info-block .wrap-info p {
  text-indent: 2em;
  padding: 5px 0;
}

.info-block .item {
  margin: 15px 0 60px;
}

.info-block .logo-brend {
  margin-bottom: 30px;
}

.info-block .logo-brend img {
  max-height: 200px;
}

.info-block .text-block {
  text-align: justify;
}

.info-block .wrap-items {
  width: 100%;
  justify-content: space-around;
}

.s-prods {
  text-align: center;
  background: #fff;
  padding: 150px 0;
}

.s-prods .wrap-items {
  justify-content: space-around;
}

.s-prods .title {
  margin-top: 25px;
  text-transform: uppercase;
  font-size: 28px;
}

.s-prods .img-block {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.s-prods .item,
.s-prods label {
  max-width: 330px;
}

.s-prods input {
  display: none;
}

.s-prods sup {
  display: none !important;
}

.s-products {
  background: url("../img/grey-bg.jpg") no-repeat;
  background-size: cover;
  padding: 130px 0 150px;
  position: relative;
}

.s-products form {
  height: 100%;
}

.s-products .wrap-items {
  justify-content: flex-start;
}

.s-products .item {
  width: calc(100% /  4 - 30px);
  border-radius: 30px;
  background: #fff;
  border: 3px dotted #000;
  padding: 30px 30px;
  text-align: center;
  transition: all 1.3s;
  position: relative;
  margin: 30px 15px;
}

.s-products .item .a {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.s-products .item:nth-child(4n + 4) .show-block {
  right: -15px;
}

.s-products h3 {
  text-transform: uppercase;
  font-size: 26px;
}

.s-products .content-block {
  position: relative;
  transition: all .3s;
  top: 0;
  flex-grow: 1;
}

.s-products .button-block {
  margin-top: 10px;
  transition: all .5s;
  width: 100%;
  height: 46px;
}

.s-products .button-block span {
  display: inline-block;
  font-size: 25px;
  border-radius: 19.5px;
  border: 1px solid #000;
  width: 80%;
  padding: 10px 25px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .3s;
}

.s-products .button-block:hover span {
  background: #333;
  color: #fff;
}

.s-products .img-block {
  height: 300px;
  margin-bottom: 15px;
  cursor: pointer;
}

.s-products .img-block img {
  max-height: 100%;
}

.s-products .show-block {
  position: absolute;
  background: #fff;
  width: 500px;
  top: -15px;
  left: -15px;
  z-index: 9;
  border: 3px solid #ccc;
  border-radius: 20px;
  padding: 25px 25px;
  transition: all .5s;
  opacity: 0;
  display: none;
}

.s-products .show-block.active {
  display: block;
  opacity: 1;
}

.s-products .close-prod {
  position: absolute;
  font-size: 50px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.s-products .img-big {
  border: 2px dashed #ccc;
  border-radius: 20px;
  padding: 15px;
  margin: 0 20px;
}

.s-products .it {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.s-products .name {
  margin: 30px 0;
}

.s-products .descr {
  margin: 30px 0;
}

.s-products .ttl {
  min-width: 140px;
  text-align: left;
}

.s-products .des {
  text-align: right;
}

.s-products .mre {
  cursor: pointer;
  transition: all .3s;
}

.s-products .mre:hover {
  opacity: .8;
}

#mse2_mfilter .pagination {
  display: flex;
  justify-content: center;
}

#mse2_mfilter .pagination .page-item {
  margin: 0 10px;
}

#mse2_mfilter .pagination .page-item.active a {
  font-weight: bold;
}

.s-product.s-section {
  padding-top: 200px;
}

.s-product.s-section .section-wrap {
  min-height: 70vh;
  display: flex;
}

.s-product.s-section .it {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.s-product.s-section .name {
  margin: 30px 0;
}

.s-product.s-section .descr {
  margin: 30px 0;
}

.baltic-fish-01 {
  width: 23%;
  height: 100%;
  background: url("../img/meat.png") no-repeat;
  background-size: contain;
  position: absolute;
  top: 600px;
  right: -200px;
  display: none;
}

#mse2_filters .disabled,
#mse2_filters .disabled a {
  color: #aaa;
}

.ass-block {
  color: #fff;
  text-align: center;
}

.ass-block .wrap-info {
  background: 0 0;
}

.ass-block .wrap-info {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  text-transform: uppercase;
  justify-content: space-between;
}

.ass-block .wrap-info label {
  width: 30%;
}

.ass-block label {
  cursor: pointer;
}

.ass-block .item {
  width: 100%;
}

.ass-block .item-one {
  transition: all .3s;
}

.ass-block .item-one p {
  text-indent: 0;
  padding: 0;
}

.ass-block .item-one img {
  max-height: 70px;
}

.ass-block .item-one:hover {
  opacity: 1;
}

.ass-block input {
  display: none;
}

.ass-block .wrap-img {
  height: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 15px;
}

.s-all-br {
  background: #fff;
  padding: 150px 0;
}

.s-all-br .wrap-items {
  justify-content: center;
}

.s-all-br .item {
  border: 1px dotted #000;
  width: calc(100% / 6 - 30px);
  height: 190px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 15px;
  padding: 15px;
}

.s-all-br .item img {
  max-height: 160px;
}

.s-all-br input {
  display: none;
}

.ass-block .wrap-info {
  font-size: 18px;
}

.ass-block .wrap-info button {
  width: 100%;
  text-transform: uppercase;
  border: none;
  background: 0 0;
  text-align: center;
  font-size: 23px;
  color: #fff;
}

.ass-block .wrap-info button.hidden {
  display: block !important;
}

.item-one {
  opacity: .6;
}

form sup {
  display: none;
}

footer {
  text-align: center;
  padding: 30px 0;
}

#thanks {
  text-align: center;
}

#thanks .form-title {
  margin-bottom: 15px;
}

#thanks .mfp-close {
  color: #fff !important;
}

.error {
  display: none;
  color: red;
}

.form-popup {
  max-width: 330px;
  background: rgba(28, 28, 28, 0.76);
  color: #fff;
  padding: 40px 25px;
  border-radius: 20px;
  position: relative;
  margin: auto;
}

.form-temp .form-title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 25px;
}

.form-temp input {
  width: 100%;
  height: 40px;
}

.form-temp label {
  margin-bottom: 10px;
  display: block;
}

.check-block {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.check-block label {
  display: flex;
  align-items: center;
  margin: 0;
}

.check-block input {
  margin-right: 10px;
  min-width: 16px;
  width: 16px;
  height: 16px;
}

.fotorama__stage {
  max-height: 90vh !important;
  top: 0 !important;
  width: auto !important;
  margin: 0 auto;
}

.fotorama__wrap--css3 .fotorama__stage .fotorama__img {
  max-height: 70vh !important;
  top: 0 !important;
  width: auto !important;
  margin: 0 auto;
}

.fotorama__nav-wrap {
  position: absolute;
  top: 76vh;
}

.fotorama__thumb img {
  min-height: 100%;
}

.close {
  display: none;
}

.mobil-mnu {
  display: none;
}

.mobil-mnu {
  color: #000;
}

.toggle-mnu {
  display: block;
  width: 28px;
  height: 28px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
}

.toggle-mnu span:after {
  top: 18px;
}

.toggle-mnu span {
  position: relative;
  display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle-mnu.on span {
  background-color: transparent;
}

.toggle-mnu.on span:before {
  transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
  transform: rotate(-45deg) translate(6px, -7px);
}

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */

/* Medium Devices, Desktops */

/* Small Devices, Tablets */

/* Extra Small Devices, Phones */

/* Extra Small Devices, Phones */

/* Custom, iPhone Retina */

/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */

/* Extra Small Devices, Phones */

/* Small Devices, Tablets */

/* Medium Devices, Desktops */

/* Large Devices, Wide Screens */

/* Large Devices, Wide Screens */

@media only screen and (min-width: 320px) {
  /**/
}

@media only screen and (min-width: 480px) {
  /**/
}

@media only screen and (min-width: 768px) {
  /**/
}

@media only screen and (min-width: 992px) {
  /**/
}

@media only screen and (min-width: 1200px) {
  /**/
}

@media only screen and (min-width: 2000px) {
  /**/

  .s-header,
  .header-block {
    background-size: cover !important;
    height: 900px;
  }

  .s-header .section-wrap,
  .header-block .section-wrap {
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .s-header .info-block,
  .header-block .info-block {
    position: static;
  }
}

@media only screen and (max-width: 1750px) {
  /**/

  .h2,
  h2 {
    font-size: 50px;
  }

  body,
  .ass-block .wrap-info button {
    font-size: 20px;
  }

  .h1-block {
    min-height: 600px;
    height: calc(100vh - 115px);
  }

  .s-contacts .wrap-items,
  .s-about {
    min-height: 770px;
  }

  .s-prods .img-block img {
    height: 160px;
  }

  .info-block .wrap-info {
    padding: 25px 35px;
  }

  .header-block {
    height: calc(100vh - 115px);
  }
}

@media only screen and (max-width: 1600px) {
  /**/

  .h2,
  h2 {
    font-size: 40px;
  }

  body,
  .ass-block .wrap-info button {
    font-size: 18px;
  }

  .h1-block {
    min-height: 600px;
    height: calc(100vh - 100px);
  }

  .s-contacts .wrap-items {
    min-height: auto;
    height: auto;
  }

  .s-about .text-block {
    padding: 60px 25px;
  }

  .s-advantages h4 {
    font-size: 20px;
  }

  .s-advantages .img-block {
    height: 70px;
  }

  .s-advantages .img-block img {
    max-height: 100%;
  }

  .s-assortment h3 {
    font-size: 30px;
  }

  .s-assortment .img-block {
    max-height: 260px;
  }

  .s-assortment .img-block img {
    max-height: 100%;
  }

  .h2-block {
    margin-bottom: 30px;
  }

  .s-contacts {
    padding: 80px 0;
  }

  .header {
    min-height: 80px;
  }

  .s-header {
    margin-top: 100px;
    background-size: cover;
  }

  .header {
    min-height: 90px;
    padding-bottom: 0;
  }

  .s-products .item {
    padding: 15px;
  }

  .h3,
  h3 {
    font-size: 25px;
  }

  .s-products .button-block span {
    font-size: 20px;
  }

  .s-products .show-block {
    width: 400px;
  }

  .s-products .item {
    margin: 15px;
  }

  .s-all-br .item {
    height: 175px;
  }

  .s-products h3 {
    font-size: 20px;
  }

  .s-products .img-block {
    height: 250px;
  }

  .s-prods .img-block img {
    height: 115px;
  }

  .header-block {
    background-size: 100% !important;
    background-repeat: no-repeat !important;
  }

  .s-prods {
    padding: 100px 0;
  }

  .info-block .item {
    margin-bottom: 40px;
  }

  .ass-block .item-one img {
    max-height: 60px;
  }

  .ass-block .wrap-img {
    height: 70px;
  }

  .ass-block .item img {
    height: 70px;
  }

  .header-block {
    height: calc(100vh - 90px);
    margin-top: 90px;
  }

  .lang-block a {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 1500px) {
  /**/

  .header .logo {
    max-width: 120px;
  }

  .ssoc-wrap {
    font-size: 30px;
  }

  .s-about .h2-block {
    padding-top: 30px;
  }

  body,
  .ass-block .wrap-info button {
    font-size: 16px;
  }

  .h2,
  h2 {
    font-size: 35px;
  }

  .h2-block .descr {
    font-size: 18px;
  }

  .s-advantages .img-block {
    height: 70px;
  }

  .s-about .im-03 {
    bottom: -90px;
  }

  .s-about .im-05,
  .s-about .im-04 {
    bottom: -35px;
  }

  .s-assortment .img-block {
    max-height: 170px;
  }

  .s-assortment h3 {
    font-size: 22px;
  }

  .s-header {
    margin-top: 65px;
  }

  .s-contacts .section-wrap {
    position: relative;
  }

  .s-assortment {
    padding-top: 0;
  }

  .s-products .item {
    width: calc(100% / 3 - 30px);
  }

  .s-about {
    min-height: 700px;
  }

  .s-products {
    padding-bottom: 80px;
  }

  .s-all-br .item {
    height: 150px;
  }

  .s-products h3 {
    font-size: 18px;
  }

  .info-block .logo-brend img {
    max-height: 100px;
  }

  .info-block .logo-brend {
    margin-bottom: 15px;
  }

  .header-block {
    height: calc(100vh - 90px);
    margin-top: 90px;
    max-height: 600px;
  }

  .s-prods .img-block img {
    height: 75px;
  }

  .h3,
  h3 {
    font-size: 20px;
  }

  .s-products .img-block {
    height: 200px;
  }

  .s-products .button-block span {
    font-size: 16px;
    padding: 6px 25px;
  }

  .s-prods {
    padding: 60px 0;
  }

  .s-prods .img-block {
    height: auto;
  }

  .ass-block .item-one img {
    max-height: 50px;
  }

  .ass-block .wrap-img {
    height: 60px;
  }

  .ass-block .wrap-info {
    font-size: 16px;
  }

  .ishome .s-header {
    margin-top: 70px;
  }

  .ishome .s-header .h1-block {
    min-height: 525px;
    height: calc(100vh - 200px);
  }

  .ass-block .item img {
    height: 50px;
  }

  .info-block .item {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  /**/

  .s-products .show-block {
    width: 350px;
  }

  .s-all-br {
    padding: 100px 0;
  }

  .ass-block .item img {
    height: 50px;
  }

  .info-block .item {
    margin-bottom: 15px;
  }

  .ass-block .item-one img {
    max-height: 40px;
  }

  .header-block {
    max-height: 485px;
  }

  .ass-block .wrap-info {
    font-size: 14px;
  }

  .s-all-br .item {
    height: 115px;
  }

  .s-all-br .item img {
    max-height: 80px;
  }

  .info-block {
    width: 45%;
  }

  .info-block .wrap-info {
    padding: 25px 20px;
  }

  .info-block .text-block {
    font-size: 15px;
  }
}

@media only screen and (max-width: 992px) {
  /**/

  .top-header-wrap .close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 30px;
    cursor: pointer;
    display: block;
    width: 30px;
  }

  .top-header-wrap ul {
    height: auto;
  }

  .top-header-wrap .navigation {
    position: absolute;
    right: 0;
    top: 60px;
    width: 100%;
    background: #f2f6e3;
    z-index: 99;
    padding: 15px;
    display: none;
  }

  .top-header-wrap .navigation li {
    display: block;
  }

  .top-header-wrap .navigation li a {
    padding: 10px 15px;
    display: block;
    min-height: 51px;
  }

  .top-header-wrap nav ul {
    display: block;
  }

  .mobil-mnu {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .top-header {
    position: relative;
  }

  .header {
    padding: 10px 0 0;
    min-height: 70px;
  }

  .header .logo {
    max-width: 100%;
    flex-grow: 1;
  }

  .header .logo a {
    max-width: 100px;
  }

  .s-about .text-block {
    padding: 40px 15px;
  }

  .h2-block .descr {
    font-size: 16px;
  }

  .h2,
  h2 {
    font-size: 30px;
  }

  .h1-block {
    min-height: 400px;
    height: auto;
  }

  .top-header-wrap {
    padding-right: 50px;
  }

  .s-products .item {
    width: calc(100% / 2 - 30px);
  }

  .s-brends .item img {
    max-width: 100%;
  }

  .s-about .wrap-item {
    margin-bottom: 70px;
  }

  .s-about {
    min-height: auto;
    height: auto;
  }

  .s-all-br .item {
    width: calc(100% / 4 - 30px);
  }

  .s-products .show-block {
    width: 110%;
  }

  .info-block {
    width: 50%;
  }

  .ass-block .item img {
    max-height: 70px;
  }

  .header-block {
    margin-top: 70px;
    background-size: auto !important;
  }

  .s-all-br {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 768px) {
  /**/

  .s-section {
    padding: 70px 0;
  }

  .top-header-wrap {
    padding-left: 10px;
    padding-right: 60px;
  }

  .top-header-wrap .navigation {
    padding: 10px;
  }

  .h2,
  h2 {
    font-size: 25px;
  }

  .s-about .text-block {
    max-width: 95%;
    padding: 20px 10px;
    line-height: 1.4;
  }

  .s-about .im-01 {
    top: -10px;
  }

  .s-about .im-02 {
    top: -55px;
  }

  .s-about .im-03 {
    bottom: -30px;
  }

  .s-about,
  .s-contacts .wrap-items {
    height: auto;
  }

  .s-advantages .item {
    padding: 0 10px;
  }

  .s-advantages h4 {
    font-size: 16px;
  }

  .s-advantages .title {
    margin: 10px 0;
  }

  .s-advantages .text-block p {
    display: inline;
  }

  .s-assortment .item {
    padding: 0 10px;
  }

  .s-advantages .img-block {
    height: 50px;
  }

  .s-assortment h3 {
    font-size: 18px;
  }

  .s-contacts .item-form {
    width: 100%;
    padding: 0 15px;
  }

  .s-contacts .item-cont {
    width: 100%;
    padding: 0 15px;
  }

  .s-contacts .cont-im-01 {
    display: none;
  }

  .s-contacts .ssoc-block {
    font-size: 30px;
  }

  .h3,
  h3 {
    font-size: 20px;
  }

  .s-contacts .item-info {
    margin: 15px 0;
  }

  .s-contacts .button-form {
    font-size: 16px;
  }

  .top-header-wrap .ssoc-block {
    margin: 0 15px;
  }

  .top-header-wrap .ssoc-block .ssoc-wrap {
    font-size: 24px;
  }

  .s-header {
    background: url("../img/header-bg.png") 100% 50% no-repeat;
    background-size: cover;
  }

  .s-assortment {
    padding: 0;
  }

  .s-products .show-block {
    width: 200%;
  }

  .s-products .item:nth-child(even) .show-block {
    left: -100%;
  }

  .s-products .wrap-items {
    margin: 0 15px;
  }

  .info-block {
    width: 80%;
  }

  .ass-block.header-block {
    background-position: 8% 50% !important;
  }

  .ass-block.header-block:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .s-all-br .wrap-items {
    padding: 0 15px;
  }

  .wrap-img-mob {
    display: block;
  }

  .isAlba .header-block {
    background: 0 0 !important;
    max-height: 100%;
    height: auto;
  }

  .isAlba .info-block {
    width: 100%;
    position: static;
    margin: 0px auto 30px;
  }

  .info-block .logo-brend img {
    max-width: 33%;
  }

  .ishome .s-header .h1-block {
    min-height: 50vw;
    height: 50vw;
  }
}

@media only screen and (max-width: 580px) {
  /**/

  .h1-block {
    min-height: 230px;
  }

  .s-about .wrap-item {
    margin-bottom: 30px;
  }

  .s-advantages .item {
    width: 48%;
  }

  .s-contacts textarea {
    height: 100px;
  }

  .header .logo {
    min-width: 100px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 480px) {
  /**/

  body,
  .ass-block .wrap-info button {
    font-size: 14px;
  }

  .s-brends {
    padding: 30px 0;
  }

  .s-about {
    min-height: 0px;
  }

  .h2-block {
    padding: 0 10px;
  }

  .h2-block .descr {
    font-size: 14px;
  }

  .h2,
  h2 {
    font-size: 20px;
  }

  .s-advantages .item {
    width: 50%;
  }

  .s-advantages h4 {
    font-size: 14px;
  }

  .s-advantages .title {
    margin-bottom: 0;
  }

  .s-assortment .item {
    width: calc(50% - 30px);
  }

  .s-assortment h3 {
    font-size: 16px;
  }

  .form-temp label {
    margin: 15px 0;
  }

  .s-assortment {
    padding-bottom: 0;
  }

  .s-contacts {
    padding-bottom: 40px;
  }

  .header {
    min-height: 60px;
  }

  .s-about .im-02 {
    top: -20px;
  }

  .s-about .im-01 {
    top: 10px;
  }

  .s-contacts .button-form {
    width: 100%;
  }

  .s-all-br .item {
    width: calc(100% / 3 - 30px);
    height: 85px;
  }

  .s-products .button-block span {
    width: 100%;
    padding: 5px;
    font-size: 14px;
  }

  .s-products .img-block {
    height: 115px;
  }

  .s-products .item {
    margin: 10px;
    width: calc(100% / 2 - 20px);
    border: 1px dotted #000;
    border-radius: 10px;
  }

  .s-products h3 {
    font-size: 12px;
  }

  .s-products .button-block {
    height: 30px;
  }

  .s-products .show-block {
    padding: 15px;
  }

  .s-products .show-block h3 {
    font-size: 14px;
  }

  .s-products .close-prod {
    font-size: 40px;
  }

  .s-products .descr {
    margin: 0;
  }

  .s-products .name {
    margin: 15px 0;
  }

  .lang-block a {
    width: 25px;
  }

  .top-header-wrap .navigation {
    top: 50px;
  }
}

@media only screen and (max-width: 320px) {
  /**/
}