@import "vars";

/*==========  Desktop First  ==========*/
@media only screen and (max-width : 1750px) {
	/**/

	.h2, h2 {
		font-size: 50px; }
	body, .ass-block .wrap-info button {
		font-size: 20px; }
	.h1-block {
		min-height: 600px;
		height: calc(100vh - 115px); }
	.s-contacts .wrap-items, .s-about {
		min-height: 770px; }
	.s-prods .img-block img {
		height: 160px; }
	.info-block .wrap-info {
		padding: 25px 35px; }
	.header-block {
		height: calc(100vh - 115px); } }

@media only screen and (max-width : 1600px) {
	/**/

	.h2, h2 {
		font-size: 40px; }
	body, .ass-block .wrap-info button {
		font-size: 18px; }
	.h1-block {
		min-height: 600px;
		height: calc(100vh - 100px); }
	.s-contacts .wrap-items {
		min-height: auto;
		height: auto; }
	.s-about .text-block {
		padding: 60px 25px; }
	.s-advantages h4 {
		font-size: 20px; }
	.s-advantages .img-block {
		height: 70px;
		img {
			max-height: 100%; } }
	.s-assortment h3 {
		font-size: 30px; }
	.s-assortment .img-block {
		max-height: 260px;
		img {
			max-height: 100%; } }
	.h2-block {
		margin-bottom: 30px; }
	.s-contacts {
		padding: 80px 0; }
	.header {
		min-height: 80px; }
	.s-header {
		margin-top: 100px;
		background-size: cover; }
	.header {
		min-height: 90px;
		padding-bottom: 0; }
	.s-products .item {
		padding: 15px; }
	.h3, h3 {
		font-size: 25px; }
	.s-products .button-block span {
		font-size: 20px; }
	.s-products .show-block {
		width: 400px; }
	.s-products .item {
		margin: 15px; }
	.s-all-br .item {
		height: 175px; }
	.s-products h3 {
		font-size: 20px; }
	.s-products .img-block {
		height: 250px; }
	.s-prods .img-block img {
		height: 115px; }
	.header-block {
		background-size: 100% !important;
		background-repeat: no-repeat !important; }
	.s-prods {
		padding: 100px 0; }
	.info-block .item {
		margin-bottom: 40px; }
	.ass-block .item-one img {
		max-height: 60px; }
	.ass-block .wrap-img {
		height: 70px; }
	.ass-block .item {
		img {
			height: 70px; } }
	.header-block {
		height: calc(100vh - 90px);
		margin-top: 90px; }
	.lang-block a {
		height: 30px;
		width: 30px;
		line-height: 30px; } }




@media only screen and (max-width : 1500px) {
	/**/
	.header .logo {
		max-width: 120px; }
	.ssoc-wrap {
		font-size: 30px; }
	.s-about .h2-block {
		padding-top: 30px; }
	body, .ass-block .wrap-info button {
		font-size: 16px; }
	.h2, h2 {
		font-size: 35px; }
	.h2-block .descr {
		font-size: 18px; }
	.s-advantages .img-block {
		height: 70px; }
	.s-about .im-03 {
		bottom: -90px; }
	.s-about .im-05, .s-about .im-04 {
		bottom: -35px; }
	.s-assortment .img-block {
		max-height: 170px; }
	.s-assortment h3 {
		font-size: 22px; }
	.s-header {
		margin-top: 65px; }
	.s-contacts .section-wrap {
		position: relative; }
	.s-assortment {
		padding-top: 0; }
	.s-products .item {
		width: calc(100% / 3 - 30px); }

	// .s-products .item:nth-child(3n+3) .show-block
	// 	left: -200px
	.s-about {
		min-height: 700px; }
	.s-products {
		padding-bottom: 80px; }
	.s-all-br .item {
		height: 150px; }
	.s-products h3 {
		font-size: 18px; }
	.info-block .logo-brend img {
		max-height: 100px; }
	.info-block .logo-brend {
		margin-bottom: 15px; }
	.header-block {
		height: calc(100vh - 90px);
		margin-top: 90px;
		max-height: 600px; }
	.s-prods .img-block img {
		height: 75px; }
	.h3, h3 {
		font-size: 20px; }
	.s-products .img-block {
		height: 200px; }
	.s-products .button-block span {
		font-size: 16px;
		padding: 6px 25px; }
	.s-prods {
		padding: 60px 0; }
	.s-prods .img-block {
		height: auto; }
	.ass-block .item-one img {
		max-height: 50px; }
	.ass-block .wrap-img {
		height: 60px; }
	.ass-block .wrap-info {
		font-size: 16px; }
	.ishome .s-header {
		margin-top: 70px;
		.h1-block {
			min-height: 525px;
			height: calc(100vh - 200px); } }
	.ass-block .item img {
		height: 50px; }
	.info-block .item {
		margin-bottom: 10px; } }


/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/
	.s-products .show-block {
		width: 350px; }
	.s-all-br {
		padding: 100px 0; }
	.ass-block .item img {
		height: 50px; }
	.info-block .item {
		margin-bottom: 15px; }
	.ass-block .item-one img {
		max-height: 40px; }
	.header-block {
		max-height: 485px; }
	.ass-block .wrap-info {
		font-size: 14px; }
	.s-all-br .item {
		height: 115px; }
	.s-all-br .item img {
		max-height: 80px; }
	.info-block {
		width: 45%; }
	.info-block .wrap-info {
		padding: 25px 20px; }
	.info-block .text-block {
		font-size: 15px; } }

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	/**/
	.top-header-wrap {
		.close {
			position: absolute;
			right: 15px;
			top: 15px;
			font-size: 30px;
			cursor: pointer;
			display: block;
			width: 30px; }
		ul {
			height: auto; }
		.navigation {
			position: absolute;
			right: 0;
			top: 60px;
			width: 100%;
			background: #f2f6e3;
			z-index: 99;
			padding: 15px;
			display: none;
			li {
				display: block;
				a {
					padding: 10px 15px;
					display: block;
					min-height: 51px; } } } }
	.top-header-wrap {
		nav ul {
			display: block; } }

	.mobil-mnu {
		display: block;
		position: absolute;
		right: 15px;
		top: 15px; }
	.top-header {
		position: relative; }
	.header {
		padding: 10px 0 0;
		min-height: 70px; }
	.header .logo {
		max-width: 100%;
		flex-grow: 1;
		a {
			max-width: 100px; } }
	.s-about .text-block {
		padding: 40px 15px; }
	.h2-block .descr {
		font-size: 16px; }
	.h2, h2 {
		font-size: 30px; }
	.h1-block {
		min-height: 400px;
		height: auto; }
	.top-header-wrap {
		padding-right: 50px; }
	.s-products .item {
		width: calc(100% / 2 - 30px); }
	.s-brends .item img {
		max-width: 100%; }
	.s-about {
		.wrap-item {
			margin-bottom: 70px; } }
	.s-about {
		min-height: auto;
		height: auto; }
	.s-all-br .item {
		width: calc(100% / 4 - 30px); }
	.s-products .show-block {
		width: 110%; }
	.info-block {
		width: 50%; }
	.ass-block .item {
		img {
			max-height: 70px; } }
	.header-block {
		margin-top: 70px;
		background-size: auto !important; }

	.s-all-br {
		padding: 60px 0; } }




/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	/**/
	.s-section {
		padding: 70px 0; }
	.top-header-wrap {
		padding-left: 10px;
		padding-right: 60px; }
	.top-header-wrap .navigation {
		padding: 10px; }
	.h2, h2 {
		font-size: 25px; }
	.s-about .text-block {
		max-width: 95%;
		padding: 20px 10px;
		line-height: 1.4; }
	.s-about .im-01 {
		top: -10px; }
	.s-about .im-02 {
		top: -55px; }
	.s-about .im-03 {
		bottom: -30px; }
	.s-about, .s-contacts .wrap-items {
		height: auto; }
	.s-advantages .item {
		padding: 0 10px; }
	.s-advantages h4 {
		font-size: 16px; }
	.s-advantages {
		.title {
			margin: 10px 0; }
		.text-block {
			p {
				display: inline; } } }
	.s-assortment .item {
		padding: 0 10px; }
	.s-advantages .img-block {
		height: 50px; }
	.s-assortment h3 {
		font-size: 18px; }
	.s-contacts {
		.item-form {
			width: 100%;
			padding: 0 15px; }
		.item-cont {
			width: 100%;
			padding: 0 15px; } }
	.s-contacts .cont-im-01 {
		display: none; }
	.s-contacts .ssoc-block {
		font-size: 30px; }
	.h3, h3 {
		font-size: 20px; }
	.s-contacts .item-info {
		margin: 15px 0; }
	.s-contacts .button-form {
		font-size: 16px; }
	.top-header-wrap .ssoc-block {
		margin: 0 15px;
		.ssoc-wrap {
			font-size: 24px; } }
	.s-header {
		background: url("../img/header-bg.png") 100% 50% no-repeat;
		background-size: cover; }
	.s-assortment {
		padding: 0; }
	.s-products .show-block {
		width: 200%; }
	.s-products .item {
		&:nth-child(even) {
			.show-block {
				left: -100%; } } }
	.s-products .wrap-items {
		margin: 0 15px; }
	.info-block {
		width: 80%; }
	.ass-block.header-block {
		background-position: 8% 50% !important;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background: rgba(#000, .7); } }
	.s-all-br .wrap-items {
		padding: 0 15px; }
	.wrap-img-mob {
		display: block; }
	.isAlba {
		.header-block {
			background: 0 0 !important;
			max-height: 100%;
			height: auto; }
		.info-block {
			width: 100%;
			position: static;
			margin: 0px auto 30px; } }
	.info-block .logo-brend img {
		max-width: 33%; }

	.ishome .s-header .h1-block {
		min-height: 50vw;
		height: 50vw; } }



/* Extra Small Devices, Phones */
@media only screen and (max-width : 580px) {
	/**/
	.h1-block {
		min-height: 230px; }
	.s-about .wrap-item {
		margin-bottom: 30px; }
	.s-advantages .item {
		width: 48%; }
	.s-contacts textarea {
		height: 100px; }
	.header .logo {
		min-width: 100px;
		margin-right: 15px; } }







/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	/**/
	body, .ass-block .wrap-info button {
		font-size: 14px; }

	.s-brends {
		padding: 30px 0; }
	.s-about {
		min-height: 0px; }
	.h2-block {
		padding: 0 10px;
		.descr {
			font-size: 14px; } }
	.h2, h2 {
		font-size: 20px; }
	.s-advantages .item {
		width: 50%; }
	.s-advantages h4 {
		font-size: 14px; }
	.s-advantages .title {
		margin-bottom: 0; }
	.s-assortment .item {
		width: calc(50% - 30px); }
	.s-assortment h3 {
		font-size: 16px; }
	.form-temp label {
		margin: 15px 0; }
	.s-assortment {
		padding-bottom: 0; }
	.s-contacts {
		padding-bottom: 40px; }
	.header {
		min-height: 60px; }
	.s-about .im-02 {
		top: -20px; }
	.s-about .im-01 {
		top: 10px; }
	.s-contacts .button-form {
		width: 100%; }
	.s-all-br .item {
		width: calc(100% / 3 - 30px);
		height: 85px; }
	.s-products .button-block span {
		width: 100%;
		padding: 5px;
		font-size: 14px; }
	.s-products .img-block {
		height: 115px; }
	.s-products .item {
		margin: 10px;
		width: calc(100% / 2 - 20px);
		border: 1px dotted #000;
		border-radius: 10px; }
	.s-products h3 {
		font-size: 12px; }
	.s-products .button-block {
		height: 30px; }
	.s-products .show-block {
		padding: 15px;
		h3 {
			font-size: 14px; } }
	.s-products .close-prod {
		font-size: 40px; }
	.s-products .descr {
		margin: 0; }
	.s-products .name {
		margin: 15px 0; }
	.lang-block a {
		width: 25px; }
	.top-header-wrap .navigation {
		top: 50px; } }


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 2000px) {
	/**/
	.s-header, .header-block {
		background-size: cover !important;
		height: 900px;
		.section-wrap {
			height: 900px;
			display: flex;
			align-items: center;
			justify-content: flex-end; }
		.info-block {
			position: static; } } }
